export const suggestColorHex = [
  '#6993ff99',
  '#EC5B56',
  '#FF2750',
  '#FF6D00',
  '#F08C34',
  '#F9DE6D',
  '#F7C200',
  '#398175',
  '#4BC5B6',
  '#A9D0D5',
  '#00C8DA',
  '#242F6D',
  '#0080C9',
  '#3983DC',
  '#475DE6',
  '#7A54E7',
  '#BEA8EC'
];

export const suggestColorRgba = [
  //   rgba(105, 147, 255, 0.6)
  { r: 105, g: 147, b: 255, a: 0.6 },
  //   rgba(236, 91, 86, 1)
  { r: 236, g: 91, b: 86, a: 1 },
  //   rgba(255, 39, 80, 1)
  { r: 255, g: 39, b: 80, a: 1 },
  //   rgba(255, 109, 0, 1)
  { r: 255, g: 109, b: 0, a: 1 },
  //   rgba(240, 140, 52, 1)
  { r: 240, g: 140, b: 52, a: 1 },
  //   rgba(249, 222, 109, 1)
  { r: 249, g: 222, b: 109, a: 1 },
  //   rgba(247, 194, 0, 1)
  { r: 247, g: 194, b: 0, a: 1 },
  //   rgba(57, 129, 117, 1)
  { r: 57, g: 129, b: 117, a: 1 },
  //   rgba(75, 197, 182, 1)
  { r: 75, g: 197, b: 182, a: 1 },
  //   rgba(169, 208, 213, 1)
  { r: 169, g: 208, b: 213, a: 1 },
  //   rgba(0, 200, 218, 1)
  { r: 0, g: 200, b: 218, a: 1 },
  //   rgba(36, 47, 109, 1)
  { r: 36, g: 47, b: 109, a: 1 },
  //   rgba(0, 128, 201, 1)
  { r: 0, g: 128, b: 201, a: 1 },
  //   rgba(57, 131, 220, 1)
  { r: 57, g: 131, b: 220, a: 1 },
  //   rgba(71, 93, 230, 1)
  { r: 71, g: 93, b: 230, a: 1 },
  //   rgba(122, 84, 231, 1)
  { r: 122, g: 84, b: 231, a: 1 },
  //   rgba(190, 168, 236, 1)
  { r: 190, g: 168, b: 236, a: 1 }
];



export function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


export function fadeColor(hex, fadePercent) {
  let rgb = hexToRgb(hex);
  let fadedR = Math.round(rgb.r * (1 - fadePercent));
  let fadedG = Math.round(rgb.g * (1 - fadePercent));
  let fadedB = Math.round(rgb.b * (1 - fadePercent));
  return 'rgb(' + fadedR + ',' + fadedG + ',' + fadedB + ')';
}

exports.navMenu = {
  CREATE_SHOP: 'createShop',
  EDIT_SHOP: 'editShop',
  ANNOUNCEMENT: 'AnnouncementsPage',
  FEATURE_PACKAGE: 'FeaturePackagePage'
};

exports.defaultTableProps = {
  custom: true,
  sizePerPageList: [
    {
      text: '3',
      value: 3
    },
    {
      text: '5',
      value: 5
    },
    {
      text: '10',
      value: 10
    }
  ],
  sizePerPage: 10,
  page: 1
};

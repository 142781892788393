import { FormattedDate, FormattedMessage } from 'react-intl';
import sanitizeHtml from 'sanitize-html';
import { pathProduct, pathPromotion, pathCoupon, pathSpecialpackage, pathBroadcast, pathAdvertisement } from './path';
import status_confitm_icon from '../../../../../../assets/icons_status/status_confitm_icon.svg';
import status_waiting_icon from '../../../../../../assets/icons_status/status_pending_icon.svg';

export const textOverflow = (str, size) => {
  if (str && str.length >= size) return str.slice(0, size) + '...';
  return str;
};

export const getTime = date => {
  if (!date) return 'ไม่ระบุ';
  return <FormattedDate value={date} day="2-digit" month="long" year="numeric" hour="2-digit" minute="2-digit" />;
};

export const getBroadcastTime = ({ broadcastTime, updatedAt }) => {
  const { type, send } = broadcastTime || {};

  if (type === 'scheduled' && send) return getTime(send);
  if (type === 'sent') return getTime(updatedAt);
  return 'none';
};

export const getRecipients = (data, group, formatMessage) => {
  const { type, group: userGroup, filter } = data || {};
  if (type === 'all') return <FormattedMessage id="mgm.form.all.member" />;
  if (type === 'group' && group && userGroup)
    return group
      .filter(e => userGroup.includes(e?._id))
      .map(e => e?.name)
      .join(', ');
  if (type === 'condition' && filter)
    return Object.entries(filter)
      .map(([key, value]) => {
        let name = '';
        if (['gender', 'birthMonth', 'sales'].includes(key)) {
          name = value;
        } else {
          const obj = JSON.parse(value);
          const min = obj?.min || 0;
          const max = obj?.max ? obj?.max : `${obj?.min}+`;
          name = obj?.max ? `${min} - ${max}` : max;
        }
        const label = formatMessage({ id: `filter.${key === 'birthMonth' ? 'birth' : key}` });
        return `${label} : ${name}`;
      })
      .join(', ');
};

export const getStatusIcon = status => {
  if (status === 'draft') return <i className="fa fa-pen" style={{ color: '#337eff' }} />;
  if (status === 'scheduled') return <img src={status_waiting_icon} alt=" " style={{ width: 18, aspectRatio: 1 }} />;
  if (status === 'sent') return <img src={status_confitm_icon} alt=" " style={{ width: 18, aspectRatio: 1 }} />;
};

export const getStatus = status => {
  if (status === 'draft') return <FormattedMessage id="mgm.status.draft" />;
  if (status === 'scheduled') return <FormattedMessage id="mgm.status.scheduled" />;
  if (status === 'sent') return <FormattedMessage id="mgm.status.sent" />;
  if (status === 'failed') return <FormattedMessage id="mgm.status.failed" />;
};

export const getImg = item => {
  const image = item?.image || [],
    productImg = item?.product || [],
    promotionImg = item?.promotion || [],
    couponImg = item?.coupon || [],
    packageImg = item?.package || [],
    advertisementImg = item?.advertisement || [];

  const broadcastImgList = image.map(e => `${pathBroadcast}/${e}`);
  const productImgList = productImg.map(e => `${pathProduct}/${e?.image[0]}`);
  const promotionImgList = promotionImg.map(e => `${pathPromotion}/${e?.image[0]}`);
  const couponImgList = couponImg.map(e => `${pathCoupon}/${e?.image[0]}`);
  const packageImgList = packageImg.map(e => `${pathSpecialpackage}/${e?.image[0]}`);

  const advertisementImgList = advertisementImg.map(e => {
    if (e?.image[0]) return `${pathAdvertisement}/${e?.image[0]}`;
    if (e?.product) return `${pathProduct}/${e?.product?.image[0]}`;
    if (e?.promotion) return `${pathPromotion}/${e?.promotion?.image[0]}`;
    if (e?.coupon) return `${pathCoupon}/${e?.coupon?.image}`;
    if (e?.package) return `${pathSpecialpackage}/${e?.package?.image[0]}`;
  });

  return broadcastImgList.concat(
    productImgList,
    promotionImgList,
    couponImgList,
    packageImgList,
    advertisementImgList.filter(e => e)
  );
};

export const BROADCAST_STATUS = [
  {
    _id: 1,
    name: ['draft', 'ร่าง']
  },
  {
    _id: 2,
    name: ['scheduled', 'ตั้งเวลา']
  }

  // {
  //   _id: 3,
  //   name: ['sent', 'ส่งแล้ว']
  // }
];

export const initialFilter = {
  filter: {
    group: [],
    status: [],
    searchText: ''
  }
};

export const resetButtonStyle = {
  background: 'none',
  color: 'red',
  border: 'none',
  padding: 0,
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit'
};

export const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach(key => {
      if (['imagePreview', 'error'].includes(key)) return;
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else if (data instanceof File) {
    formData.append('image', data);
  } else {
    if (data) formData.append(parentKey, data);
  }
};

export const jsonToFormData = data => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

export const initMGM = {
  image: { referrer: null, referee: null },
  imagePreview: { referrer: null, referee: null },
  error: {},
  name: '',
  status: null,
  detailReferrer: '',
  detailReferee: '',
  minShare: 1,
  referrerReward: {
    type: 'none',
    reward: {
      promotion: null,
      coupon: null,
      point: null,
      wallet: null
    }
  },
  refereeReward: {
    type: 'none',
    reward: {
      promotion: null,
      coupon: null,
      point: null,
      wallet: null
    },
    requireUse: false
  },
  condition: '1',
  durationTime: { start: null, end: null },
  broadcastTime: { type: null, send: null },
  recipients: { group: [], type: 'all' },
  createdAt: null,
  updatedAt: null
};

export const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, options)
});

export const defaultSanitizeOptions = {
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr'
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    // We don't currently allow img itself by default, but
    // these attributes would make sense if we did.
    img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false
};

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_helpers';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import FreePersonIcon from '../../../../../assets/free_person.png';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { logout } from '../../../../../app/modules/Auth/_redux/authCrud';
import { useAlert } from 'react-alert';
import { BACKEND_URL } from '../../../../../config';
import { addComma } from '../../../../../app/utils/comma';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useIsWalletActive } from '../../../../../app/hooks/useFeatureFlag';

const Screenmode = {
  UserProfile: 'profile',
  Edit: 'edit',
  ChangePassword: 'password'
};

const QuickUser = props => {
  const alert = useAlert();
  const history = useHistory();
  const { intl } = props;
  const dispatch = useDispatch();
  const { user, shop, authToken, shopSetting, shopPointandWalletSetting, regisField } = useSelector(
    state => state.auth
  );
  const activeWallet = useIsWalletActive();

  const customField = regisField?.customField;
  const custom1 = customField?.[0];
  const custom2 = customField?.[1];
  const custom3 = customField?.[2];
  const customField1 = custom1?.field ? { [custom1?.field]: user?.[custom1?.field] } : {};
  const customField2 = custom2?.field ? { [custom2?.field]: user?.[custom2?.field] } : {};
  const customField3 = custom3?.field ? { [custom3?.field]: user?.[custom3?.field] } : {};

  const pointText =
    shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.point' });
  const walletText =
    shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.credit' });

  const [changePasswordForm, setChangePasswordForm] = useState({ old_password: '', password: '', re_password: '' });
  const [mode, setMode] = useState(Screenmode.UserProfile);
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthdate: '',
    shippingAddress: '',
    remark: '',
    phone: '',
    countryDialCode: null
  });

  const logoutClick = async () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    const loginPath = user.type === 'admin' ? '/admin/login' : `/shop/${user.seqId}/login`;
    await logout();
    props.logout();
    history.push(loginPath);
  };

  const handleClearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    mappingUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const mappingUserProfile = () => {
    if (user) {
      setUserProfile({
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        email: user.email,
        phone: user.phone,
        birthdate: user.birthdate,
        shippingAddress: user.shippingAddress,
        remark: user.remark,
        countryDialCode: user.countryDialCode,
        ...customField1,
        ...customField2,
        ...customField3
      });
    }
  };
  const test = async () => {
    await axios.post(`${BACKEND_URL}/announcement/test`, { headers: { Authorization: `Bearer ${authToken}` } });
    alert.success(
      intl.formatMessage({
        id: 'recheck.notification.alert'
      })
    );
  };

  const onSubmit = async event => {
    event.preventDefault();
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (userProfile.email && !emailRegex.test(userProfile.email)) {
      alert.success(
        intl.formatMessage({
          id: 'alert.invalid.email.format'
        })
      );
      return;
    }

    if (!userProfile.phone) {
      alert.error(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_PHONE'
        })
      );

      return;
    }

    try {
      const { data } = await axios.patch(`${BACKEND_URL}/customer/${user.id}`, {
        ...userProfile,
        countryDialCode: userProfile.countryDialCode
      });
      if (data.success) {
        alert.success(
          intl.formatMessage({
            id: 'alert.edit.profile.success'
          })
        );
      }
      //user.point, user.credit from data.data is a field in user not shopUser where the actual points are stored; they must be removed.
      const { point, credit, ...validFieldsInUser } = data.data;

      dispatch({
        type: auth.actionTypes.UserLoaded,
        payload: {
          user: { ...user, ...validFieldsInUser, id: validFieldsInUser._id, seqId: user.seqId, type: user.type }
        }
      });
      setMode(Screenmode.UserProfile);
    } catch (err) {
      console.log(err);
      alert.error(err.response?.data?.message);
    }
  };

  const onSubmitChangePassword = async event => {
    event.preventDefault();

    if (changePasswordForm.re_password !== changePasswordForm.password) {
      alert.error('Password do not match. Please enter password again.');
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      return;
    }

    if (changePasswordForm.re_password.length < 6 || changePasswordForm.password.length < 6) {
      alert.error('Password must have at least 6 characters.');
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      return;
    }

    try {
      const { data } = await axios.patch(`${BACKEND_URL}/customer/${user.id}`, changePasswordForm);
      if (data.success) {
        alert.success('Change password success.');
        dispatch({
          type: auth.actionTypes.UserLoaded,
          payload: { user: { ...data.data, id: data.data._id, seqId: user.seqId, type: user.type } }
        });
        setChangePasswordForm({ old_password: '', password: '', re_password: '' });
        setMode(Screenmode.UserProfile);
      } else {
        alert.error(data.message);
        setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      }
    } catch (error) {
      alert.error(error.response.data.msg || error.response.data.message);
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
    }
  };

  const onCloseButton = () => {
    setMode(Screenmode.UserProfile);
    mappingUserProfile();
  };

  const onChange = e => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const onDateChange = date => {
    setUserProfile({ ...userProfile, birthdate: new Date(date) });
  };

  const handleChangeRoleToCustomer = async () => {
    alert.removeAll();
    if (window.localStorage.getItem('THEME_PREVIEW') || window.localStorage.getItem('IMG_PREVIEW')) {
      window.localStorage.removeItem('THEME_PREVIEW');
      window.localStorage.removeItem('IMG_PREVIEW');
      window.location.reload(false);
    }

    try {
      if (user.type !== 'customer') {
        const { data } = await axios.put(`${BACKEND_URL}/user/role`, { id: user.shopUserId, role: 'customer' });
        if (data.success) {
          dispatch({
            type: auth.actionTypes.UserLoaded,
            payload: { user: { ...user, type: 'customer', demoRole: user.type, loggedInBranch: data.newBranch } }
          });
          alert.success(intl.formatMessage({ id: 'alert.change.role.success' }));
        }
      } else {
        const { data } = await axios.put(`${BACKEND_URL}/user/role`, { id: user.shopUserId, role: user.demoRole });
        if (data.success) {
          dispatch({
            type: auth.actionTypes.UserLoaded,
            payload: { user: { ...user, type: user.demoRole, demoRole: user.demoRole, loggedInBranch: data.newBranch } }
          });
          alert.success(intl.formatMessage({ id: 'alert.change.role.success' }));
        }
      }
    } catch (e) {
      alert.error('Something went wrong, Please contact admin.');
    }
  };

  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const previewNav = previewTheme?.navColor;
  const previewFont = previewTheme?.fontColor;
  const isPreview = previewNav || previewFont ? true : false;

  const initStyle = {
    background: '#1e1e2d'
  };
  const colorInit = {
    color: '##B5B5C3'
  };

  const [background, setBackground] = useState(initStyle);
  const [fontColor, setFontColor] = useState(colorInit);

  useEffect(() => {
    const navColor = shopSetting?.backgroundColor?.navColor;
    const fontColor = shopSetting?.backgroundColor?.fontColor;
    const isShopSetting = navColor || fontColor ? true : false;

    if (isPreview && previewNav) {
      setBackground({ background: previewNav });
    }
    if (isPreview && previewFont) {
      setFontColor({ color: previewFont });
    }
    if (isShopSetting && fontColor) {
      setFontColor({ color: fontColor });
    }
    if (isShopSetting && navColor) {
      setBackground({ background: navColor });
    }
  }, [shopSetting]);

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
      style={{ ...background, color: ' rgb(181, 181, 195)' }}
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          {mode === Screenmode.UserProfile && intl.formatMessage({ id: 'user.profile' })}
          {mode === Screenmode.Edit && intl.formatMessage({ id: 'edit.your.profile' })}
          {mode === Screenmode.ChangePassword && intl.formatMessage({ id: 'change.password' })}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
          onClick={() => onCloseButton()}
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={mode}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="fade"
        >
          <div>
            {user && mode === Screenmode.UserProfile && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user">
                <div className="d-flex align-items-center mt-5 justify-content-between">
                  <div className="symbol symbol-100 mr-5 symbol-light-success">
                    {user && (user.facebookProfile || (user.lineProfile && user.lineProfile.pictureUrl)) ? (
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${
                            user.type === 'admin' ? '' : user.facebookProfile || user.lineProfile.pictureUrl
                          })`
                        }}
                      />
                    ) : (
                      <IconContainer>
                        <PersonIcon src={FreePersonIcon} />
                      </IconContainer>
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <div className="navi mt-2 mb-5">
                      <a href="#" className="navi-item">
                        <span className="navi-link p-0 pb-2">
                          <span className="navi-icon mr-1">
                            <span className="svg-icon-lg svg-icon-primary">
                              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')}></SVG>
                            </span>
                          </span>
                          <span
                            style={{ color: 'rgb(181, 181, 195)' }}
                            className={`navi-text ${fontColor === colorInit ? 'text-muted' : ''} text-hover-primary`}
                          >
                            {user.email}
                          </span>
                        </span>
                      </a>
                    </div>
                    <button className="btn purple-button btn-bold mb-2" onClick={logoutClick}>
                      {intl.formatMessage({ id: 'sign.out' })}
                    </button>
                    <button className="btn purple-button btn-bold py-1 mb-2" onClick={handleClearLocalStorage}>
                      {intl.formatMessage({ id: 'clear.website.data' })}
                    </button>
                  </div>
                </div>
                <div className="separator separator-solid mt-8 mb-5" style={{ borderColor: '#C4C4C4' }} />
                <div className="navi navi-spacer-x-0 pl-3">
                  {user.firstName && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.firstname' })}
                        </div>
                        <div style={{ ...fontColor }}>{user.firstName}</div>
                      </div>
                    </div>
                  )}
                  {user.lastName && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.lastname' })}
                        </div>
                        <div style={{ ...fontColor }}>{user.lastName}</div>
                      </div>
                    </div>
                  )}
                  {user.gender && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.gender' })}
                        </div>
                        <div style={{ ...fontColor }}>
                          {user?.gender === 'male'
                            ? intl.formatMessage({ id: 'gender.male' })
                            : user?.gender === 'female'
                            ? intl.formatMessage({ id: 'gender.female' })
                            : user?.gender === 'lgbtq+'
                            ? intl.formatMessage({id:'gender.lgbtq+'})
                            : intl.formatMessage({ id: 'gender.not.specified' })}
                        </div>
                      </div>
                    </div>
                  )}
                  {user.phone && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.mobile' })}
                        </div>
                        <div style={{ ...fontColor }}>{user.phone}</div>
                      </div>
                    </div>
                  )}
                  {user.email && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.email' })}
                        </div>
                        <div style={{ ...fontColor }}>{user.email}</div>
                      </div>
                    </div>
                  )}
                  {user.birthdate && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.birthdate' })}
                        </div>
                        <div style={{ ...fontColor }}>
                          {user.birthdate ? moment(user.birthdate).format('DD/MM/YYYY') : '-'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div
                        style={{ ...fontColor }}
                        className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                      >
                        {pointText}
                      </div>
                      <div style={{ ...fontColor }}>{addComma(user.expirePoint + user.point)}</div>
                    </div>
                  </div>

                  <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div
                        style={{ ...fontColor }}
                        className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                      >
                        {intl.formatMessage(
                          {
                            id: 'menu.accumulated.point'
                          },
                          {
                            point: pointText
                          }
                        )}
                      </div>
                      <div style={{ ...fontColor }}>{addComma(user.accumulatedPoint)}</div>
                    </div>
                  </div>

                  {activeWallet && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {walletText}
                        </div>
                        <div style={{ ...fontColor }}>{addComma(user.expireWallet + user.credit)}</div>
                      </div>
                    </div>
                  )}
                  {/*new*/}
                  <div className="navi-link mb-4">
                    <div className="navi-text">
                      {user.memberGroups?.length > 0 ? (
                        <>
                          <div
                            style={{ ...fontColor }}
                            className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                          >
                            {intl.formatMessage({ id: 'menu.member.group' })}
                          </div>
                          {user.memberGroups.map(group => (
                            <div key={group._id} style={{ ...fontColor }}>
                              {group.name}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'menu.member.group' })} :{' '}
                          {intl.formatMessage({ id: 'menu.no.group' })}
                        </div>
                      )}
                    </div>
                  </div>
                  {custom1?.active && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {custom1?.name}
                        </div>
                        <div style={{ ...fontColor }}>{user?.[custom1?.field] || '-'}</div>
                      </div>
                    </div>
                  )}
                  {custom2?.active && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {custom2.name}
                        </div>
                        <div style={{ ...fontColor }}>{user?.[custom2?.field] || '-'}</div>
                      </div>
                    </div>
                  )}
                  {custom3?.active && (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {custom3?.name}
                        </div>
                        <div style={{ ...fontColor }}>{user?.[custom3?.field] || '-'}</div>
                      </div>
                    </div>
                  )}
                  {shop && user && shop.isDemo && (user.type !== 'customer' || user.demoRole !== 'customer') ? (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div
                          style={{ ...fontColor }}
                          className={` ${fontColor === colorInit ? 'text-muted' : ''} font-weight-bold`}
                        >
                          {intl.formatMessage({ id: 'user.change.to.customer' })}
                        </div>
                        <FormControlLabel
                          control={
                            <Switch checked={user.type === 'customer'} onClick={() => handleChangeRoleToCustomer()} />
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* {user.shippingAddress && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        {intl.formatMessage({ id: 'user.shipping.address' })}
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.shippingAddress}</div>
                    </div>
                  </div>}
                  {user.remark && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        {intl.formatMessage({ id: 'user.remark' })}
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.remark}</div>
                    </div>
                  </div>} */}
                </div>
                <div
                  className="separator separator-solid my-7"
                  style={{ borderWidth: '2px', borderColor: '#C4C4C4' }}
                />
                <div className="d-flex justify-content-center">
                  {(user && user.type === 'customer' ? shop?.customerCanEditCustomer ?? true : true) && (
                    <button className="btn purple-button btn-bold mr-3" onClick={() => setMode(Screenmode.Edit)}>
                      {intl.formatMessage({ id: 'edit.your.profile' })}
                    </button>
                  )}
                  {/* {user && user.type !== 'customer' && (
                    <button className="btn purple-button btn-bold" onClick={() => setMode(Screenmode.ChangePassword)}>
                      {intl.formatMessage({ id: 'change.password' })}
                    </button>
                  )} */}
                </div>

                <div className="d-flex justify-content-center" style={{ marginTop: `10px` }}>
                  {user && user.type !== 'customer' && (
                    <button
                      className="btn purple-button btn-bold"
                      onClick={() => {
                        test();
                      }}
                    >
                      {intl.formatMessage({ id: 'recheck.notification' })}
                    </button>
                  )}
                </div>
                {shop && user.type !== 'customer' ? (
                  <ExpiredDateContainer>
                    <DateLabelStyled>
                      {intl.formatMessage({ id: 'create.shop.date' })}:{' '}
                      {shop.createdAt ? moment(shop.createdAt).format('DD/MM/YYYY') : '-'}
                    </DateLabelStyled>
                    <DateLabelStyled>
                      {intl.formatMessage({ id: 'exipred.shop.date' })}:{' '}
                      {shop.expiryDate ? moment(shop.expiryDate).format('DD/MM/YYYY') : '-'}
                    </DateLabelStyled>
                  </ExpiredDateContainer>
                ) : null}
              </div>
            )}
            {user && mode === Screenmode.Edit && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={e => onSubmit(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.firstname' })}</div>
                    <input
                      className="form-control"
                      name="firstName"
                      value={userProfile.firstName}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.lastname' })}</div>
                    <input
                      className="form-control"
                      name="lastName"
                      value={userProfile.lastName}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.email' })}</div>
                    <input
                      className="form-control"
                      name="email"
                      value={userProfile.email}
                      onChange={e => onChange(e)}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.phone' })}</div>
                    <input
                      className="form-control"
                      name="phone"
                      value={userProfile.phone}
                      onChange={e => onChange(e)}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.birthdate' })}</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="form-control"
                        inputProps={{
                          style: {
                            fontFamily: `Poppins, Helvetica, "sans-serif"`,
                            fontWeight: 400,
                            color: 'rgb(70, 78, 95)',
                            display: 'block',
                            padding: '0.65rem 1rem',
                            border: '1px solid #E5EAEE',
                            borderRadius: '0.42rem'
                          },
                          'aria-readonly': true
                        }}
                        InputProps={{
                          disableUnderline: true
                        }}
                        format="dd/MM/yyyy"
                        value={userProfile.birthdate}
                        onChange={date => onDateChange(date)}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <input className="form-control"/> */}
                  </div>
                  {custom1?.active && (
                    <div className="form-group mb-4">
                      <div className="text-muted font-weight-bold">{custom1?.name}</div>
                      <input
                        className="form-control"
                        name={custom1?.field}
                        value={userProfile?.[custom1?.field]}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  )}
                  {custom2?.active && (
                    <div className="form-group mb-4">
                      <div className="text-muted font-weight-bold">{custom2?.name}</div>
                      <input
                        className="form-control"
                        name={custom2?.field}
                        value={userProfile?.[custom2?.field]}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  )}
                  {custom3?.active && (
                    <div className="form-group mb-4">
                      <div className="text-muted font-weight-bold">{custom3?.name}</div>
                      <input
                        className="form-control"
                        name={custom3?.field}
                        value={userProfile?.[custom3?.field]}
                        onChange={e => onChange(e)}
                      />
                    </div>
                  )}
                  {/* <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      {intl.formatMessage({ id: 'user.shipping.address' })}
                    </div>
                    <textarea className="form-control" rows="3" name="shippingAddress" value={userProfile.shippingAddress} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      {intl.formatMessage({ id: 'user.remark' })}
                    </div>
                    <textarea className="form-control" rows="3" name="remark" value={userProfile.remark} onChange={(e) => onChange(e)} />
                  </div> */}
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light btn-bold"
                      onClick={() => {
                        setMode(Screenmode.UserProfile);
                        mappingUserProfile();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.cancel' })}
                    </button>
                    <button type="submit" className="btn btn-bold purple-button">
                      {intl.formatMessage({ id: 'button.submit' })}
                    </button>
                  </div>
                </form>
              </div>
            )}
            {user && mode === Screenmode.ChangePassword && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={e => onSubmitChangePassword(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_OLD_PASSWORD" />
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      name="old_password"
                      value={changePasswordForm.old_password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, old_password: e.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.password' })}</div>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={changePasswordForm.password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, password: e.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.repassword' })}</div>
                    <input
                      type="password"
                      className="form-control"
                      name="re_password"
                      value={changePasswordForm.re_password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, re_password: e.target.value })}
                    />
                  </div>
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light btn-bold"
                      onClick={() => {
                        setMode(Screenmode.UserProfile);
                        mappingUserProfile();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.cancel' })}
                    </button>
                    <button type="submit" className="btn btn-bold purple-button">
                      {intl.formatMessage({ id: 'button.submit' })}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(QuickUser));

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
`;

const PersonIcon = styled.img`
  width: 100px;
  height: 100px;
`;

const ExpiredDateContainer = styled.div`
  background-color: #17b987;
  color: #000;
  margin: 20px 15px 20px 15px;
  padding: 10px 0 5px 0;
  text-align: center;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
`;

const DateLabelStyled = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const CountryConfig = {
  MY: {
    country: 'MY',
    currency: 'MYR',
    phonePrefix: '+60',
    i18n: 'en',
    defaultTimezone: 'Asia/Kuala_Lumpur'
  },
  TH: {
    country: 'TH',
    currency: 'THB',
    phonePrefix: '+66',
    i18n: 'th',
    defaultTimezone: 'Asia/Bangkok'
  },
  PH: {
    country: 'PH',
    currency: 'PHP',
    phonePrefix: '+63',
    i18n: 'en',
    defaultTimezone: 'Asia/Manila'
  },
  TW: {
    country: 'TW',
    currency: 'TWD',
    phonePrefix: '+886',
    i18n: 'zh-TW',
    defaultTimezone: 'Asia/Taipei'
  },
  ID: {
    country: 'ID',
    currency: 'IDR',
    phonePrefix: '+62',
    i18n: 'en',
    defaultTimezone: 'Asia/Jakarta'
  }
};

export default CountryConfig;

import React, { useEffect, useState, useMemo } from 'react';
import { AsideMenuList } from './AsideMenuList';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { BACKEND_URL, FRONTEND_URL } from '../../../../../config';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true)
    };
  }, [uiService]);

  const initStyle = {
    background: ''
  };
  const { user, authToken, shop, shopSetting } = useSelector(state => state.auth);

  const [background, setBackground] = useState(initStyle);
  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const previewNav = previewTheme?.navColor;
  const isPreview = previewNav ? true : false;

  useEffect(() => {
    const navColor = shopSetting?.backgroundColor?.navColor;
    const isShopSetting = navColor ? true : false;

    if (isPreview && previewNav) {
      setBackground({ background: previewNav });
    }
    if (isShopSetting && navColor) {
      setBackground({ background: navColor });
    }
     // else {
    //   setBackground(initStyle);
    // }
  }, [shopSetting]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        style={{ ...background }}
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 `}
        // {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} />
      </div>
      {/* end::Menu Container */}
    </>
  );
}
